import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from '@/App'
import reportWebVitals from '@/reportWebVitals'
import ConsoleProvider from '@/providers/ConsoleProvider'
import { isProduction } from './helpers/node.helpers'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <ConsoleProvider>
      <App />
    </ConsoleProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (!isProduction()) reportWebVitals(console.log)
